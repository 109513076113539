.explorer {
    /*background-color: cornflowerblue;*/
    color: azure;
}

.explorer .path {
    color: white;
    font-weight: 400;
    padding: 10px;
}

ul.explorer {
    list-style-type: none;
    margin: 0;
    padding: 0;
    /*font-size: small;*/
}

.explorer li:hover {
    background-color: #5a2601;
    /*font-size: larger;*/
    color: white;
    font-weight: 400;
}

.explorer .up {
    /*background-color: cornflowerblue;*/
    color: lightblue;
}

.explorer .hit {
    background-color: #963f01;
    /*background-color: darkorange;*/
    color: #fff;
}

.item-fs {
    font-weight: 100;
    color: #999;
}

.item-folder {
    font-weight: 400;
    color: #bbb;
}

.item-folder i {
    font-weight: 100;
}

.item-fortune-index {
    font-family: monospace;
    color: gray;
}

.hit > .item-fortune-index {
    color: #fff;
}

.item-fortune-text {
    font-weight: 100;
}
